<template>
    <div style="height: 100%;overflow: auto" v-if="newsData">
      <headerderail :headertitle="headertitle" :backshow="backshow"></headerderail>
      <div class="ivu-row" style="background: white;padding: 5px;border-bottom: 1px solid #eee;">
        <h5 class="newstitle p_2">{{newsData.title}}</h5>
        <p class="newssubtitle p_2">{{newsData.subtitle}}</p>
      </div>
      <div class="ivu-row" style="margin-bottom: 38px;"  v-if="newsData">
        <img :src="newsData.cover_image.url" style="width: 100%">

        <div style="background: white;padding: 3px" v-html="newsData.content"></div>
      </div>
<!--      <div class="ivu-row" style="height:35px;width:100%;background: white;padding: 5px;border-top: 1px solid #eee;position: fixed;bottom: 0">-->
<!--      </div>-->
    </div>
</template>

<script>
  // import { todoStorage } from 'libs/storage'
  import headerderail from 'cp/v-header/headerdetail.vue'
  import {
    getInfoById
  } from 'api/news'
  export default {
    name: 'newsdetail',
    data () {
      return {
        headertitle: '详情',
        backshow: true,
        newsData: null
      }
    },
    components: {
      headerderail
    },
    computed: {
    },
    created () {
    },
    methods: {
      getDataExcute (id) {
        let t = this
        t.showLoading()
        getInfoById(id).then(res => {
          console.log(res)
          t.newsData = res.data
          t.hideLoading()
        }, function (error) {
          t.hideLoading()
          console.log(error)
        })
      }
    },
    mounted () {
      let t = this
      // t.newsData = todoStorage.getSelectNews
      // console.log(t.newsData)
      t.getDataExcute(t.$route.params['newsid'])
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">
    @import "news.styl"
</style>
